<template>
  <v-container fluid class="mt-n6">
    <v-row>
      <!-- DESKTOP -->
      <v-col md="4" lg="3" class="hidden-sm-and-down ml-n3">
        <!-- INQUIRY -->
        <v-card
          v-if="checkAllData"
          class="cardHeight"
          elevation="2"
          width="100%"
          style="overflow-hidden"
        >
          <v-toolbar color="#df6464" dark>
            <v-toolbar-title>{{
              language == "en" ? "Sent tray" : "送信済みトレイ"
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="refresh()" icon color="white">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ language == "en" ? "Refresh" : "リフレッシュ" }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-text-field
            v-model="search"
            dense
            filled
            solo
            clearable
            rounded
            @click:clear="search = ''"
            color="#df6464"
            :label="language == 'en' ? 'Search' : '検索'"
            prepend-inner-icon="mdi-magnify"
            class="mr-3 ml-3 mb-n5 mt-2"
          ></v-text-field>

          <v-row class="mr-3 ml-3 mb-n5 mt-2">
            <v-col>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                :return-value-sync="date1"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    label="Date From:"
                    prepend-inner-icon="mdi-calendar"
                    v-model="date1"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date1" :show-current="false" scrollable no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                :return-value-sync="date2"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    hide-details
                    label="Date To:"
                    prepend-inner-icon="mdi-calendar"
                    v-model="date2"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                  ></v-text-field>
                </template>
                <!--  @change="filterByDate()" -->
                <v-date-picker v-model="date2" :show-current="false" scrollable no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mr-3 ml-3 mb-n5"
            ><v-col>
              <v-btn block dense outlined color="primary" @click="searchInbox()">
                <v-icon left> mdi-refresh </v-icon>
                search
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mr-3 ml-3 mb-n1 mt-2">
            <v-col>
              <h3 style="float: right">Total:{{ count }}</h3>
            </v-col>
          </v-row>
          <v-divider class="mb-0"></v-divider>

          <v-list
            three-line
            height="500px"
            row
            wrap
            class="listCard"
            v-if="items.length != 0"
          >
            <template v-for="(item, value) in items">
              <v-list-item
                :key="value.ConfirmationID"
                @click="hideMessage(item.ConfirmationID)"
                tag="span"
                :to="{ name: 'reply', params: { id: item.ConfirmationID } }"
              >
                <v-list-item-content>
                  <v-list-item-title :style="changeFont(item.SeenBy).fonts">
                    <v-icon v-if="item.Status == 'Finished'" color="primary"
                      >mdi-flag</v-icon
                    >

                    <img
                      class="mr-1"
                      v-if="changeFont(item.SeenBy).imgBoolean"
                      src="../../assets/blueCircle.png"
                      alt=""
                      style="width: 13px; height: 11px"
                    />{{ item.CustomerCode }} - {{ item.Subject }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="contentAlign ml-3">{{
                    item.MessageContent
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="ml-1">
                  <span>{{ getTime(item.DateCreated) }}</span>
                  <br />
                  <span v-html="item.ClientDate"></span>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider :key="value.ConfirmationID"></v-divider>
            </template>

            <transition name="fade">
              <div class="loading" v-show="loading">
                <span><v-icon>mdi-loading mdi-spin</v-icon></span> Loading
              </div>
            </transition>
            <v-card v-intersect="infiniteScrolling"></v-card>
          </v-list>
          <div v-else class="noRecords" style="width: 100%">
            <v-img src="../../assets/inbox.png" width="65" style="margin: auto"> </v-img>
            <b style="font-size: 17px; color: silver">{{
              language == "en" ? "No Message Found" : "メッセージが見つかりません"
            }}</b>
          </div>
          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>

      <!-- FULL WIDTH -->
      <v-col v-bind:class="rowInbox">
        <!-- INQUIRY -->
        <v-card elevation="0" v-if="checkAllData">
          <v-text-field
            v-model="search"
            @click:clear="search = ''"
            dense
            filled
            solo
            rounded
            clearable
            color="#df6464"
            :label="language == 'en' ? 'Search' : '検索'"
            prepend-inner-icon="mdi-magnify"
            class="mb-n5"
          ></v-text-field>
          <v-list v-if="items.length != 0" three-line class="listCardMobile"
            ><br />

            <template v-for="(item, value) in items">
              <v-list-item
                :key="value.ConfirmationID"
                @click="hideInbox(item.ConfirmationID)"
                tag="span"
                :to="{ name: 'reply', params: { id: item.ConfirmationID } }"
              >
                <v-list-item-content>
                  <v-list-item-title :style="changeFont(item.SeenBy).fonts"
                    ><img
                      class="mr-1"
                      v-if="changeFont(item.SeenBy).imgBoolean"
                      src="../../assets/blueCircle.png"
                      alt=""
                      style="width: 13px; height: 11px"
                    />{{ item.CustomerCode }} - {{ item.Subject }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="contentAlign ml-3">{{
                    item.MessageContent
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="ml-1">
                  <span v-html="item.ClientDate"></span>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider :key="value.ConfirmationID"></v-divider>
            </template>
          </v-list>
          <div v-else class="noRecords" style="width: 100%">
            <v-img src="../../assets/inbox.png" width="65" style="margin: auto"> </v-img>
            <b style="font-size: 17px; color: silver; margin: auto">{{
              language == "en" ? "No Message Found" : "メッセージが見つかりません"
            }}</b>
          </div>
          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>

      <!-- REPLY -->
      <v-col class="overflow-y-auto" md="8" lg="9" v-bind:class="rowReply">
        <div class="text-center mt-16" v-if="!$route.params.id">
          <b style="font-size: 23px; color: silver">{{
            language == "en" ? "No Message Selected" : "メッセージが選択されていません"
          }}</b
          ><br />
          <v-img src="../../assets/empty-email.png" width="100" style="margin: auto">
          </v-img>
        </div>
        <v-btn color="error" @click="showInbox" to="/inbox" class="hidden-md-and-up ml-5"
          >Back</v-btn
        >

        <transition name="fade">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import keys from "../../functions/alert";
import axios from "axios";
import store from "@/store";
import moment from "moment-timezone";
import confirmationTable from "../../functions/httpRequest/ExternalConnection/ConfirmationTable";

export default {
  data() {
    return {
      loading: false,
      page: 10,
      search: "",
      socket: "",
      show: true,
      checkAllData: false,
      rowInbox: "hidden-md-and-up",
      rowReply: "hidden-sm-and-down",
      userID: "",
      name: "",
      routerPath: "",
      type: store.state.userinfo.Type,
      items: [],
      date1: moment().subtract(1, "year").format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      count: "",
    };
  },
  created() {
    // GET LOAD DATA FUNCTION

    this.loadData();
    this.dateCount();
    // this.DeptCount();
    // this.infiniteScrolling()
    // CHECK USER TYPE
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }
    // GET SOCKET IO
    this.socket = this.$io;

    // GET REALTIME MESSAGE IN INBOX
    this.socket.on("recievedMessage", (data) => {
      this.items.push({
        ConfirmationID: data.ConfirmationID,
        DateCreated: data.DateCreated,
        From: data.From,
        InquiryItem: data.InquiryItem,
        InquiryType: data.InquiryType,
        MessageContent: data.MessageContent,
        SenderID: data.SenderID,
        Status: data.Status,
        Subject: data.Subject,
        To: data.To,
      });
    });
  },
  computed: {
    // LANGUAGE LOCALE
    language() {
      return this.$i18n.locale;
    },
    //CHECK FOR ROUTE PATH
    checkRoutes() {
      // alert("TEST")
      if (this.routerPath == "/inbox/reply") return "";
      else return "inbox/reply";
    },
    //*****************************//
    //  FILTER/SEARCH RECORD DESC  //
    //*****************************//

    // filteredAndSorted() {
    //   function compare(a, b) {
    //     if (a.Subject < b.Subject) return -1;
    //     if (a.Subject > b.Subject) return 1;
    //     return 0;
    //   }
    //   const newItem = this.items
    //     .filter((res) =>
    //       res.Subject.toLowerCase().includes(this.search.toLowerCase())
    //     )
    //     .sort(compare);
    //   newItem.sort((a, b) => {
    //     const c = new Date(a.DateCreated);
    //     const d = new Date(b.DateCreated);
    //     return d - c;
    //   });
    //   return newItem;
    // },
  },
  watch: {
    // WATCH PATH CHANGE
    $route(to) {
      this.routerPath = to.path;
    },
  },
  methods: {
    refresh() {
      location.reload();
    },
    getTime(time) {
      if (!store.state.userinfo.accType) {
        return moment(time).tz("Asia/Manila").format("hh:mm A");
      } else {
        // country = "japan";
        return moment(time).tz("Asia/Manila").add(1, "hour").format("hh:mm A");
      }
    },
    searchInbox() {
      if (this.search) {
        this.searchControlCode();
        return false;
      } else {
        alert("Please Input the fields");
        return false;
      }
    },
    async dateCount() {
      await axios
        .get(
          `${keys.auth.BaseUrl}/getCountFrom/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          console.log(res.data);
          this.count = res.data.length;
          console.log(res.data.length);
        });
    },
    filterDate() {
      //  this.loading = true;

      axios
        .get(
          `${keys.auth.BaseUrl}/getFromConfirmation/${this.page}/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          // Create a Set to hold unique ConfirmationID values
          const uniqueIDs = new Set();
          // Filter out items with duplicate ConfirmationID
          const uniqueItems = res.data.filter((item) => {
            if (!uniqueIDs.has(item.ConfirmationID)) {
              uniqueIDs.add(item.ConfirmationID);
              return true;
            }
            return false;
          });

          this.items = Object.assign(uniqueItems);
          this.count = uniqueItems.length;
        });
    },
    searchControlCode() {
      this.page++;

      axios
        .get(
          `${keys.auth.BaseUrl}/getSearchCodeFrom/${this.page}/${store.state.userinfo.FullName}/${this.search}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          let searcFilter = res.data.filter((rec) => {
            if (this.search == rec.CustomerCode) {
              return rec.CustomerCode;
              // console.log(rec,"HOTDOG")
            } else if (this.search == rec.Subject) {
              return rec.Subject;
              // console.log(rec.Subject, "CHICKEND");
            } else {
              return rec;
            }
          });
          this.items = Object.assign(searcFilter);
          this.count = res.data.length;
        });
    }, // filterByDate() {
    //   axios
    //     .get(
    //       `${keys.auth.BaseUrl}/getToConfirmation/${this.page}/${store.state.userinfo.FullName}/${this.date1}/${this.date2}`,
    //       keys.auth.apiKey
    //     )
    //     .then((res) => {
    //       this.items = [];
    //       this.items = res.data.filter((rec) => {
    //         if (rec.CustomerCode != null) {
    //           if (!rec.ReplyPerson) {
    //             rec.To = rec.To.split(",");
    //             return rec.To.indexOf(this.name) >= 0;
    //             // return rec.To == this.name
    //           } else if (
    //             !rec.ReplyPerson ||
    //             rec.ReplyPerson == store.state.userinfo.FullName
    //           ) {
    //             rec.To = rec.To.split(",");
    //             return rec.To.indexOf(this.name) >= 0;
    //           } else if (
    //             !rec.ReplyPerson ||
    //             rec.ReplyPerson == store.state.userinfo.FullName
    //           ) {
    //             rec.From = rec.From.split(",");
    //             return rec.From.indexOf(this.name) >= 0;
    //           } else {
    //             rec.From = rec.From.split(",");
    //             return rec.From.indexOf(this.name) >= 0;
    //           }
    //           // return rec.To == this.name
    //         }
    //       });
    // console.log(this.items)
    // =========
    // replyTable.GetReply(this.items.ConfirmationID).then((res) => {
    //   console.log(res.data,"REPLY")
    // })
    // console.log(this.items.length);
    // >>>>>>>>> Temporary merge branch 2
    //  console.log(res.data)
    //       for (let x in this.items) {
    //         const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
    //         const dateFormat = moment().format("YYYY-MM-DD");
    //         const momentDate = moment(dateFormat);
    //         const dateDifference = momentDate.diff(userDate, "days");

    //         if (dateDifference > 1)
    //           this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
    //             "YYYY-MM-DD"
    //           );
    //         else
    //           this.items[x].ClientDate = moment(this.items[x].DateCreated)
    //             .startOf()
    //             .fromNow();
    //       }
    //       this.checkAllData = true;
    //     });

    //   axios
    //     .get(
    //       `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
    //       keys.auth.apiKey
    //     )
    //     .then((res) => {
    //       this.count = res.data[0].DataLength;
    //       // console.log(res.data)
    //     });

    //   this.searchControlCode();
    // },

    //*******************************//
    //  CHANGE FONT OF SEEN MESSAGE  //
    //*******************************//
    //    dateCount() {
    //   axios
    //     .get(
    //       `${keys.auth.BaseUrl}/getConfirmationDate/${this.date1}/${this.date2}`,
    //       keys.auth.apiKey
    //     )
    //     .then((res) => {
    //       this.count = res.data[0].DataLength;
    //       // console.log(res.data)
    //     });
    // },

    changeFont(val) {
      // IF MESSAGE CLICK
      if (val) {
        const subject = val.split(",");
        let news = subject.filter((rec) => rec == this.userID);

        if (news.length > 0)
          return {
            fonts: "margin-left: 10px",
            imgBoolean: false,
          };
      }
      // IF NOT SEEN YET
      return {
        fonts: "font-weight:bold;font-size: 15px; margin-left: -1px",
        imgBoolean: true,
      };
    },
    //*****************************//
    //    GET CONFIRMATION API     //
    //*****************************//

    loadData() {
      axios
        .get(
          `${keys.auth.BaseUrl}/getFromConfirmation/${this.page}/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          // console.log(res.data, "572");
          // Create a Set to hold unique ConfirmationID values
          const uniqueIDs = new Set();
          // Filter out items with duplicate ConfirmationID
          const uniqueItems = res.data.filter((item) => {
            if (!uniqueIDs.has(item.ConfirmationID)) {
              uniqueIDs.add(item.ConfirmationID);
              return true;
            }
            return false;
          });

          // Now you can work with the uniqueItems array
          this.items = Object.assign(uniqueItems);

          // VALIDATE DATE FORMAT
          for (let x in this.items) {
            const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
            const dateFormat = moment().format("YYYY-MM-DD");
            const momentDate = moment(dateFormat);
            const dateDifference = momentDate.diff(userDate, "days");

            // IF CONFIRMATION DATE IS MORE THE 3 DAYS
            if (dateDifference > 1)
              this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                "YYYY-MM-DD"
              );
            else
              this.items[x].ClientDate = moment(this.items[x].DateCreated)
                .startOf()
                .fromNow();
          }
          this.checkAllData = true;
        });
    },
    // entries, observer, isIntersecting
    infiniteScrolling() {
      if (this.search) {
        this.searchControlCode();
        return false;
      }
      this.loading = true;
      setTimeout(() => {
        this.page++;
        let a = this.page++;
        let skipTotal = a + 5;
        axios
          .get(
            `${keys.auth.BaseUrl}/getFromConfirmation/${skipTotal}/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
            keys.auth.apiKey
          )
          .then((res) => {
            if (res.data.length > 1) {
              // Create a Set to hold unique ConfirmationID values
              const uniqueIDs = new Set(this.items.map((item) => item.ConfirmationID));
              // Filter out items with duplicate ConfirmationID
              const uniqueNewItems = res.data.filter((item) => {
                if (!uniqueIDs.has(item.ConfirmationID)) {
                  uniqueIDs.add(item.ConfirmationID);
                  return true;
                }
                return false;
              });
              // Concatenate the unique new items with the existing items
              this.items = this.items.concat(uniqueNewItems);

              // VALIDATE DATE FORMAT
              for (let x in this.items) {
                const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
                const dateFormat = moment().format("YYYY-MM-DD");
                const momentDate = moment(dateFormat);
                const dateDifference = momentDate.diff(userDate, "days");
                this.items[x].MessageContent = this.items[x].MessageContent.split(
                  "<br>"
                ).join("\n");
                // IF CONFIRMATION DATE IS MORE THE 3 DAYS
                if (dateDifference > 1)
                  this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                    "YYYY-MM-DD"
                  );
                else
                  this.items[x].ClientDate = moment(this.items[x].DateCreated)
                    .startOf()
                    .fromNow();
              }
              this.checkAllData = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
        this.loading = false;
      }, 2000);
    },
    //******************************************//
    //    UPDATE SEEN IN DATABASE FUNCTION     //
    //*****************************************//

    hideInbox(id) {
      // UPDATE IN DATABASE
      confirmationTable.GetSpecificConfirmation(id).then(async (res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");

          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: id,
          SeenBy: addData,
        };

        await confirmationTable.Update(dataToUpdate);
      });
      // INSERT ID IN VUEX
      for (let x in this.items)
        if (this.items[x].ConfirmationID == id) this.items[x].SeenBy = this.userID;
      this.ReplyID(id);
      this.rowInbox = "hidden-sm-and-down";
      this.rowReply = "hidden-md-and-up";
      this.show = false;
    },
    //*****************************//
    //      IF MOBILE SIZE        //
    //*****************************//
    showInbox() {
      this.rowInbox = "hidden-md-and-up";
      this.rowReply = "hidden-sm-and-down";
    },

    //*******************************//
    //     IF MOBILE SIZE UPDATE     //
    //*******************************//
    hideMessage(conID) {
      // UPDATE IN DATABASE
      confirmationTable.GetSpecificConfirmation(conID).then((res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");

          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: conID,
          SeenBy: addData,
        };
        // INSERT ID IN VUEX
        confirmationTable.Update(dataToUpdate);
      });
      for (let x in this.items)
        if (this.items[x].ConfirmationID == conID) this.items[x].SeenBy = this.userID;

      this.ReplyID(conID);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.listCard {
  overflow-x: auto;
  max-height: calc(79vh - 70px);
}
.listCardMobile {
  overflow-x: auto;
  max-height: calc(103vh - 128px);
}
.cardHeight {
  max-height: calc(90vh - 124px);
  min-height: calc(90vh - 56px);
}
.noRecords {
  color: grey;
  text-align: center;
  width: 100%;
  padding-top: 25vh;
}
.contentAlign {
  max-height: 70px;
  p {
    color: silver;
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 1;
  background: #df6464;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
