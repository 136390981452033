import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //**************************************//
  //  POST REQUEST IN CONFIRMATION TABLE  //
  //*************************************//

  Post(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertConfirmation`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //**************************************//
  //  GET REQUEST IN CONFIRMATION TABLE  //
  //*************************************//

  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getConfirmation/10`, keys.auth.apiKey)
    );
  },

  //**************************************//
  //  GET REQUEST IN CONFIRMATION TABLE  //
  //*************************************//

  GetCount() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getConfirmationCount`, keys.auth.apiKey)
    );
  },

  //****************************************************//
  //  GET SPECIFIC CONFIRMATION IN CONFIRMATION TABLE   //
  //****************************************************//

  GetSpecificConfirmation(id) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/GetSpecificConfirmation/${id}`,
        keys.auth.apiKey
      )
    );
  },

  //*****************************************//
  //  UPDATE REQUEST IN CONFIRMATION TABLE   //
  //*************************************** *//

  Update(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/UpdateConfirmation`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //*****************************************************//
  //  INSERT CORRECTION REQUEST IN CONFIRMATION TABLE   //
  //****************************************************//

  PostCorrection(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertCorrectionConfirmation`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //*****************************************************//
  //      INSERT CORRECTION REQUEST IN REPLY TABLE      //
  //****************************************************//

  PostReply(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertCorrectionReply`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

   //*****************************************************//
  //      GET CORRECTION REQUEST IN REPLY TABLE      //
  //****************************************************//

  GetReplyTo(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/getToConfirmation`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  GetReplyToSearch(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/getSearchCodeTo`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
};

export default httpRequest;
